.imageInput
    .input
        position: relative
        height: 4em

    .input > .button, input
        width: 100%
        height: 100%
        position: absolute

    .input h4
        margin-bottom: 0

    .input > input
        cursor: pointer
        opacity: 0
        height: 100%

    img
        width: 100%
