@import "node_modules/bootstrap/scss/functions"
@import "node_modules/bootstrap/scss/variables"
@import "node_modules/bootstrap/scss/mixins"

.main-container
    display: grid
    min-height: 100vh
    grid-template-rows: 5em auto 5em
    background: #1e1e1e
    color: white

.navbar
    width: 100% !important
    height: 5em
    padding-right: 0

.navbar > .container
    margin-left: 1em
    margin-right: 1em

.outlet
    grid-row: 2
    padding: 2em

.footer
    grid-row: 3

.user
    margin-left: auto

.domain, .username
    display: inline-block

.user-profile
    border-radius: 100%

// smartphones (portrait)
@include media-breakpoint-only(xs)
    .outlet
        padding: 1em

    .domain, .username
        display: none

// smartphones (landscape) & tablets (portrait)
@include media-breakpoint-up(sm)
    .main-container

// desktop
@include media-breakpoint-up(lg)
    .main-container
