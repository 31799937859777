@import "node_modules/bootstrap/scss/functions"
@import "node_modules/bootstrap/scss/variables"
@import "node_modules/bootstrap/scss/mixins"

.skill-icon
    width: 1.5em

.skill-points
    width: 50%
    vertical-align: middle

th
    width: 0

@include media-breakpoint-only(xs)
    .skill-name
        display: none

    .skill-name-head
        display: none
